import { fetchFeatureFlagsQuery } from '../api/featureFlagApi';
import { SET_FEATURE_FLAGS } from '../constants';
import { handleErrors } from '../utils/errorUtils';
import { doQuery } from '../utils/fetch';

export function setFeatureFlags(featureFlags) {
  return {
    type: SET_FEATURE_FLAGS,
    featureFlags,
  };
}

export const fetchFeatureFlags = () => async dispatch => {
  try {
    const response = await doQuery(fetchFeatureFlagsQuery());
    if (!response.ok) {
      handleErrors(response);
      throw Error('Unknown error occurred!');
    }
    const { data } = await response.json();

    dispatch(setFeatureFlags(data?.getCustomerFeatures));
  } catch (err) {
    console.log('err', err);
  }
};
