import React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toggleUserPanel } from '../../actions/tracking';
import { isUserAdmin, isAdminOrCustomerAdmin, isUserSupport, isUserDealer, isUserDeveloper, isViewOnlyAdmin } from '../../security/authorization';
import { logout } from '../../actions/logout';
import './UserWelcomePanel.css';
import cms from '../../messages';

function UserWelcomePanel() {
  const dispatch = useDispatch();
  const { OtaManagement, VinTransferPortal, SubscriptionManagement } = useSelector(state => state.featureFlag.features);
  const onClickOutside = () => dispatch(toggleUserPanel());
  const vinTransferAccess = isUserAdmin() || isUserSupport() || isUserDealer() || isViewOnlyAdmin();
  console.log('IS VIEW ONLY ADMIN ', isViewOnlyAdmin());

  const onClickLogout = e => {
    e.preventDefault();
    dispatch(logout());
  };

  const handleToggleUserPanel = e => {
    e.preventDefault();
    dispatch(toggleUserPanel());
  };

  return (
    <ClickAwayListener onClickAway={onClickOutside}>
      <div className="userWelcomePanel-container">
        {isAdminOrCustomerAdmin() && (
          <div className="userWelcomePanel-linkWrapper" data-cy="userIcon" role="button" tabIndex="0" onClick={handleToggleUserPanel}>
            <NavLink to="/user-management" className="userWelcomePanel-link">
              {cms.userManagement}
            </NavLink>
          </div>
        )}
        {isAdminOrCustomerAdmin() && (
          <div className="userWelcomePanel-linkWrapper" data-cy="userIcon" role="button" tabIndex="0" onClick={handleToggleUserPanel}>
            <NavLink to="/trailer-management" className="userWelcomePanel-link">
              {cms.trailerManagement}
            </NavLink>
          </div>
        )}
        {isUserAdmin() && SubscriptionManagement && (
          <div className="userWelcomePanel-linkWrapper" data-cy="userIcon" role="button" tabIndex="0" onClick={handleToggleUserPanel}>
            <NavLink to="/subscription-management" className="userWelcomePanel-link">
              {cms.subscriptionManagement}
            </NavLink>
          </div>
        )}
        {isAdminOrCustomerAdmin() && (
          <div className="userWelcomePanel-linkWrapper" data-cy="userIcon" role="button" tabIndex="0" onClick={handleToggleUserPanel}>
            <NavLink to="/group-management" className="userWelcomePanel-link">
              {cms.groupManagement}
            </NavLink>
          </div>
        )}
        {isUserAdmin() && (
          <div>
            <div className="userWelcomePanel-linkWrapper" data-cy="userIcon" role="button" tabIndex="0" onClick={handleToggleUserPanel}>
              <NavLink to="/customer-management" className="userWelcomePanel-link">
                {cms.customerManagement}
              </NavLink>
            </div>
            {OtaManagement && (
              <div className="userWelcomePanel-linkWrapper" data-cy="userIcon" role="button" tabIndex="0" onClick={handleToggleUserPanel}>
                <NavLink to="/ota-management" className="userWelcomePanel-link">
                  {cms.otaManagement}
                </NavLink>
              </div>
            )}
          </div>
        )}
        {isUserDeveloper() && (
          <div className="userWelcomePanel-linkWrapper" data-cy="userIcon" role="button" tabIndex="0" onClick={handleToggleUserPanel}>
            <NavLink to="/developer-message-management" className="userWelcomePanel-link">
              {cms.developerMessageManagement}
            </NavLink>
          </div>
        )}
        {vinTransferAccess && VinTransferPortal && (
          <div className="userWelcomePanel-linkWrapper" data-cy="userIcon" role="button" tabIndex="0" onClick={handleToggleUserPanel}>
            <NavLink to="/vin-transfer" className="userWelcomePanel-link">
              {cms.vinTransfer}
            </NavLink>
          </div>
        )}
        {isUserAdmin() && (
          <div className="userWelcomePanel-linkWrapper" data-cy="userIcon" role="button" tabIndex="0" onClick={handleToggleUserPanel}>
            <NavLink to="/lease-management" className="userWelcomePanel-link">
              {cms.leaseManagement}
            </NavLink>
          </div>
        )}
        <div className="userWelcomePanel-linkWrapper" onClick={onClickLogout}>
          <div className="userWelcomePanel-link">{cms.logout}</div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default UserWelcomePanel;
