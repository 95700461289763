import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { replaceSuperAdminDropdownOptionWithAllCustomers } from '../../utils/userManagementUtils';
import Select from '../Select/Select';

function CustomerSelect({
  customer,
  setCustomer,
  isDisabled = false,
  userManagementActive = false,
  clearable = false,
  hideLabel,
  required,
  customLabel,
}) {
  let { customersData, customersLoading } = useSelector(s => s.customers);
  if (userManagementActive) {
    customersData = replaceSuperAdminDropdownOptionWithAllCustomers(customersData);
  }

  return (
    <Select
      label={hideLabel ? null : customLabel ?? "Select Customer"}
      required={required}
      value={customer}
      options={customersData}
      onChange={setCustomer}
      getOptionValue={option => option?.id}
      getOptionLabel={option => option?.shortName}
      placeholder={customLabel ?? "Select Customer"}
      isDisabled={isDisabled}
      isClearable={clearable && !!customer}
      isSearchable={true}
      isLoading={customersLoading}
    />
  );
}

CustomerSelect.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    shortName: PropTypes.string.isRequired,
    longName: PropTypes.string.isRequired,
  }),
  setCustomer: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  userManagementActive: PropTypes.bool,
  clearable: PropTypes.bool,
  hideLabel: PropTypes.bool,
  customLabel: PropTypes.string,
};

export default CustomerSelect;
