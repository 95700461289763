import { hasCautionAbsSeverityLevel, hasCriticalAbsSeverityLevel } from '../constants/enums/absFaults';
import { trailerHasAbsInstalled, trailerHasTisInstalled } from './mapFilteringUtils';

export const notReportingStatus = connectivityStatus => connectivityStatus !== 'GOOD';

export const getTrailerFaultsCount = trailer => {
  let caution = null;
  let critical = null;
  if (trailer?.tireInflationStatus === 'unstable') {
    critical = critical + 1;
  }
  if (hasCriticalAbsSeverityLevel(trailer?.absFaultSeverityLevel)) {
    critical = critical + 1;
  }
  if (hasCautionAbsSeverityLevel(trailer?.absFaultSeverityLevel)) {
    caution = caution + 1;
  }
  if (trailer?.lightFault) {
    caution = caution + 1;
  }
  return { caution, critical };
};

export const trailerUnitIdOrVinMatches = (trailer, keyword) => {
  if (!trailer || !keyword) {
    return false;
  }
  return trailer?.unitId?.toUpperCase()?.includes(keyword) || trailer?.vin?.toUpperCase()?.includes(keyword);
};

export const getMaintenanceIssues = vehicles => {
  let absIssues = [];
  let tisIssues = [];
  let trailerBatteryIssues = [];
  let notReportingIssues = [];
  vehicles.forEach(vehicle => {
    const hasBattery = vehicle?.trailerBatteryStatus;
    const hasGatewayBattery = !vehicle?.trailerBatteryStatus && vehicle?.gatewayBatteryStatus;
    const hasAbsIssue = hasCautionAbsSeverityLevel(vehicle?.absFaultSeverityLevel) || hasCriticalAbsSeverityLevel(vehicle?.absFaultSeverityLevel);
    const hasTisIssue = vehicle?.tireInflationStatus === 'unstable';
    if (trailerHasAbsInstalled(vehicle) && hasAbsIssue) {
      absIssues.push(vehicle);
    }

    if (trailerHasTisInstalled(vehicle) && hasTisIssue) {
      tisIssues.push(vehicle);
    }
    if (hasBattery || hasGatewayBattery) {
      trailerBatteryIssues.push(vehicle);
    }
    if (notReportingStatus(vehicle?.connectivityStatus)) {
      notReportingIssues.push(vehicle);
    }
  });

  return { absIssues, tisIssues, notReportingIssues };
};

const removeCommasAndSplit = str => {
  const removeCommasFromString = str?.replace(/,/g, ' ');

  return removeCommasFromString?.split(' ');
};

export const findAxle = updatedSpecs => {
  const trailerAxle = updatedSpecs
    ?.find(item => item.group === 'Geometric Dimensions')
    ?.vehicleUdcs?.find(item => item.description === 'Undercarriage Design')
    ?.udctext?.toLowerCase();

  const stringToArray = removeCommasAndSplit(trailerAxle);
  const axle = stringToArray?.find(item => item === 'single' || item === 'tandem' || item === 'tridem');

  return axle ?? 'tandem';
};

export const formatHeaderData = vehicles => {
  const formattedVehicles = vehicles?.map(vehicle => ({
    ...vehicle,
    alertTypeStatusNotReporting: notReportingStatus(vehicle?.connectivityStatus),
    alertTypeStatusCaution: hasCautionAbsSeverityLevel(vehicle?.absFaultSeverityLevel) || vehicle?.lightFault,
    alertTypeStatusCritical: vehicle?.tireInflationStatus === 'unstable' || hasCriticalAbsSeverityLevel(vehicle?.absFaultSeverityLevel),
    installedSensorTypeIdsList: vehicle?.installedSensorTypeIds?.length > 0 ? vehicle?.installedSensorTypeIds?.split(',').map(Number) : null,
  }));

  const data = getMaintenanceIssues(formattedVehicles);
  const absIssues = data?.absIssues ?? [];
  const tisIssues = data?.tisIssues ?? [];
  const trailerBatteryIssues = data?.trailerBatteryIssues ?? [];
  const notReportingIssues = data?.notReportingIssues ?? [];
  const allTrailers = [...absIssues, ...tisIssues, ...trailerBatteryIssues, ...notReportingIssues];
  const uniqueTrailers = [...new Set(allTrailers)];

  const headerData = {
    lastRefreshedAt: Date.now(),
    headerData: getMaintenanceIssues(formattedVehicles),
    uniqueTrailers: uniqueTrailers,
    filteredTrailers: uniqueTrailers,
    totalTrailerNum: uniqueTrailers?.length ?? 0,
    vehicles: vehicles,
  };

  return headerData;
};
