import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import _startCase from 'lodash/startCase';
import _capitalize from 'lodash/capitalize';
import _uniq from 'lodash/uniq';
import _omitBy from 'lodash/omitBy';
import { getGpsOdometerMilesValue } from './odometerUtils';
import { getActivityLogBatteryValue } from './batteryUtils';
import { findTseFaultById } from '../constants/enums/tseFaultLevels';
import { mapTirePressureSensorStatus, mapWheelEndSensorStatus } from './hendricksonUtils';
import sensorStatusTypes from '../constants/enums/sensorStatusTypes';
import { tseSensorLocation } from '../constants/enums/tseSensorLocation';
import { displayTKFaultInfoV2, displayVoltage, engineHoursLogDisplayV2, loggerSensorsActivityLogRow, zoneInfoActivityLogRow } from './thermoKingUtils';
import { calculatePercentage } from './customMathUtils';
import { formattedSpeedValue } from './vehicleLocationUtils';

const getDoorStatus = row => {
  const status = _get(row, 'doorOpened', null);
  if (status === undefined || status === null) {
    return 'N/A';
  }

  if (!status) {
    return 'Closed';
  }

  return 'Open';
};

const getCircuitValue = lightValue => (lightValue ? _startCase(lightValue) : 'N/A');

const getLightCircuitsValues = row => {
  const redLight = _get(row, 'lightRed');
  const greenLight = _get(row, 'lightGreen');
  const yellowLight = _get(row, 'lightYellow');
  const blackLight = _get(row, 'lightBlack');
  const brownLight = _get(row, 'lightBrown');

  const lightsCircuitsArray = [redLight, greenLight, yellowLight, blackLight, brownLight];

  if (lightsCircuitsArray.some(circuit => circuit === undefined)) {
    return 'N/A';
  }

  const lightsArray = [
    `Red: ${getCircuitValue(redLight)}`,
    `Green: ${getCircuitValue(greenLight)}`,
    `Yellow: ${getCircuitValue(yellowLight)}`,
    `Black: ${getCircuitValue(blackLight)}`,
    `Brown: ${getCircuitValue(brownLight)}`,
  ];

  const lightsIgnoringTokens = ['GOOD', 'Good', 'N/A'];
  const faultsArray = (lightsArray || []).filter(circuit => !includesIgnoreCase(circuit, lightsIgnoringTokens));

  if (!_isEmpty(faultsArray)) {
    return faultsArray?.join(', ');
  }

  return row?.recorded ? 'Good' : 'N/A';
};

export const includesIgnoreCase = (target, token) => {
  const formattedTargetString = target?.toLowerCase();
  if (_isString(token)) {
    return formattedTargetString?.includes(token?.toLowerCase());
  }
  return token?.some(t => formattedTargetString?.includes(t?.toLowerCase()));
};

const getLoadStatusText = (isLoaded, loadWeight) => {
  if (isLoaded === undefined || isLoaded === null) {
    return 'N/A';
  }
  if (isLoaded) {
    return loadWeight === 'N/A' ? 'Loaded' : `Loaded - ${loadWeight}`;
  }
  return 'Unloaded';
};

export const getActivityLogDataUtil = (activityLogSensorSamples, features) => {
  if (_isEmpty(activityLogSensorSamples)) {
    return [];
  }
  const formattedActivityLogData = (activityLogSensorSamples || [])
    .map(row => {
      const {
        id,
        latitude,
        longitude,
        reverseGeoFullAddress,
        loaded,
        loadWeight,
        batteryVoltageTrailer,
        tisStatus,
        backupBatteryVoltage,
        tseFaultDetails,
        odometerAbs,
        odometerGps,
        tseFaults,
        faultLevel,
        recorded,
        tetherStatus,
        xt5300BatteryStatus,
        absFaultDescription,
        amberLampStatus,
        wheelEndDetails,
        tirePressureDetails,
        speed,
        reeferHoursDetails,
        reeferBatteryDetails,
        reeferFuelLevelDetails,
        reeferTemperatureSensorDetails,
        reeferZoneTemperatureDetails,
        reeferFaultsDetails,
      } = row;

      const reeferHoursDetailsData = reeferHoursDetails ? JSON.parse(reeferHoursDetails) : null;
      const reeferBatteryDetailsData = reeferBatteryDetails ? JSON.parse(reeferBatteryDetails) : null;
      const reeferFuelLevelDetailsData = reeferFuelLevelDetails ? JSON.parse(reeferFuelLevelDetails) : null;
      const reeferTemperatureSensorDetailsData = reeferTemperatureSensorDetails ? JSON.parse(reeferTemperatureSensorDetails) : null;
      const loggerSensorsText = loggerSensorsActivityLogRow({
        ambientTemperature: reeferTemperatureSensorDetailsData?.ambient_temperature?.value,
        humidity: reeferTemperatureSensorDetailsData?.humidity?.value,
        indSensor1: reeferTemperatureSensorDetailsData?.ind_sensor_1?.value,
        indSensor2: reeferTemperatureSensorDetailsData?.ind_sensor_2?.value,
        indSensor3: reeferTemperatureSensorDetailsData?.ind_sensor_3?.value,
        indSensor4: reeferTemperatureSensorDetailsData?.ind_sensor_4?.value,
        indSensor5: reeferTemperatureSensorDetailsData?.ind_sensor_5?.value,
        indSensor6: reeferTemperatureSensorDetailsData?.ind_sensor_6?.value,
      });
      const reeferZoneTemperatureDetailsData = reeferZoneTemperatureDetails ? JSON.parse(reeferZoneTemperatureDetails) : null;
      const zoneInfoText = zoneInfoActivityLogRow({
        setPoint1: reeferZoneTemperatureDetailsData?.set_point_1?.value,
        setPoint2: reeferZoneTemperatureDetailsData?.set_point_2?.value,
        setPoint3: reeferZoneTemperatureDetailsData?.set_point_3?.value,
        returnAir1: reeferZoneTemperatureDetailsData?.return_air_1?.value,
        returnAir2: reeferZoneTemperatureDetailsData?.return_air_2?.value,
        returnAir3: reeferZoneTemperatureDetailsData?.return_air_3?.value,
        dischargeAir1: reeferZoneTemperatureDetailsData?.discharge_air_1?.value,
        dischargeAir2: reeferZoneTemperatureDetailsData?.discharge_air_2?.value,
        dischargeAir3: reeferZoneTemperatureDetailsData?.discharge_air_3?.value,
      });
      const reeferFaultsDetailsData = reeferFaultsDetails && reeferFaultsDetails.length ? JSON.parse(reeferFaultsDetails) : null;

      return {
        'id': id,
        'Location': getLocationLogText({ latitude, longitude, reverseGeoFullAddress }),
        'Tether Status': tetherStatus || 'N/A',
        'ABS': absFaultDescription || 'N/A',
        'Amber Lamp Status': amberLampStatus || 'N/A',
        'TIS Status': tisStatus ? _startCase(tisStatus) : 'N/A',
        'Load Status': getLoadStatusText(loaded, loadWeight ?? 'N/A'),
        'Battery Voltage': getActivityLogBatteryValue(batteryVoltageTrailer ?? ''),
        'Door Status': getDoorStatus(row),
        'Lights': getLightCircuitsValues(row),
        'ABS Odometer': getGpsOdometerMilesValue(odometerAbs ?? ''),
        'GPS Mileage': getGpsOdometerMilesValue(odometerGps ?? ''),
        'TSE Fault': tseFaults ?? 'N/A',
        'Fault Level': faultLevel ? _capitalize(faultLevel) : 'N/A',
        ...((features?.TseFaultDescription ?? true) && { 'TSE Fault Details': getTseFaultLogText(tseFaultDetails) }),
        'recorded': recorded ?? 'N/A',
        'Backup Battery Voltage': getActivityLogBatteryValue(backupBatteryVoltage ?? null),
        'Wheel End': getWheelEndLogText(wheelEndDetails),
        'Individual Tire Pressure': getTirePressureLogText(tirePressureDetails),
        'Fuel Level': getFuelLevelLogTextV2(reeferFuelLevelDetailsData ?? {}),
        'Logger Sensors': loggerSensorsText,
        'Zone Info': zoneInfoText,
        'Engine Hours': engineHoursLogDisplayV2(reeferHoursDetailsData ?? {}),
        'Unit Battery Voltage': displayVoltage(reeferBatteryDetailsData?.voltage?.value),
        'Fault Info': displayTKFaultInfoV2(reeferFaultsDetailsData?.reeferFaults ?? []),
        'Speed (mph)': formattedSpeedValue(speed),
        'Battery Status': xt5300BatteryStatus,
      };
    })
    .map(it => _omitBy(it, value => !value || value === 'N/A'));

  return formattedActivityLogData;
};

export const getWheelEndLogText = wheelEndDetails => {
  const wheelEnd = wheelEndDetails && wheelEndDetails !== 'null' ? JSON.parse(wheelEndDetails) : null;
  const mappedWheelEndDetails = wheelEnd?.filter(item => item.wheelEndLocation)?.map(item => mapWheelEndSensorStatus(item));
  const distinctWheelEndStatusDetails = _uniq(mappedWheelEndDetails);
  const wheelEndStatus = _isEmpty(distinctWheelEndStatusDetails) ? sensorStatusTypes.GOOD.display : distinctWheelEndStatusDetails.join(', ');

  return mappedWheelEndDetails ? wheelEndStatus : 'N/A';
};

export const getTirePressureLogText = tirePressureDetails => {
  const tirePressure = tirePressureDetails && tirePressureDetails !== 'null' ? JSON.parse(tirePressureDetails) : null;
  const mappedTirePressureDetails = tirePressure?.filter(item => item.wheelEndLocation)?.map(item => mapTirePressureSensorStatus(item));
  const distinctTirePressureStatusDetails = _uniq(mappedTirePressureDetails);
  const tirePressureStatus = _isEmpty(distinctTirePressureStatusDetails) ? sensorStatusTypes.GOOD.display : distinctTirePressureStatusDetails.join(', ');

  return mappedTirePressureDetails ? tirePressureStatus : 'N/A';
};

export const getTseFaultLogText = tseFaultDetails =>
  tseFaultDetails && tseFaultDetails !== 'null' ? getTseFaultLevelDetail(JSON.parse(tseFaultDetails)) : 'N/A';

export const getTseFaultLevelDetail = tseFaultDetails =>
  `${formatTseFaultLevelDetail(tseFaultDetails, tseSensorLocation.FRONT_LEFT)},
  ${formatTseFaultLevelDetail(tseFaultDetails, tseSensorLocation.FRONT_RIGHT)},
  ${formatTseFaultLevelDetail(tseFaultDetails, tseSensorLocation.MIDDLE_LEFT)},
  ${formatTseFaultLevelDetail(tseFaultDetails, tseSensorLocation.MIDDLE_RIGHT)}`;

const formatTseFaultLevelDetail = (tseFaultDetails, tseSensorLocation) =>
  `${tseSensorLocation?.description} - ${findTseFaultById(tseFaultDetails[tseSensorLocation?.name]?.highestWheelEndFaultLevel)?.name ?? 'N/A'}`;

export const getLocationLogText = ({ latitude, longitude, reverseGeoFullAddress }) => {
  const locationValue = getLocationValue(latitude, longitude);
  return reverseGeoFullAddress ? `${locationValue}, \n${reverseGeoFullAddress}` : locationValue;
};

const getLocationValue = (lat, lng) => {
  const validLat = lat && typeof lat === 'number';
  const validLng = lng && typeof lng === 'number';

  if (!validLat || !validLng) {
    return 'N/A';
  }

  return `${lat.toFixed(2)}, ${lng.toFixed(2)}`;
};

export const getFuelLevelLogText = thermoKing =>
  thermoKing?.fuelLevel && thermoKing?.fuelTankSize ? calculatePercentage(thermoKing?.fuelLevel, thermoKing?.fuelTankSize) : 'N/A';

export const getFuelLevelLogTextV2 = ({ fuel_level, fuel_tank_size }) => {
  if (!fuel_level || !fuel_tank_size) {
    return 'N/A';
  }
  return `${calculatePercentage(fuel_level?.value, fuel_tank_size?.value)} %`;
};
